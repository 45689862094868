import '../disabled.styles.scss'

const ColleDisabled = () => {
    return (
        <div className='colle-disabled-container'>
            <div className='colle'>
                <div className='cadre'>
                    <div className='title'>La finance verte : un mirage ? </div>
                    <div className='content'>19.5/20</div>
                </div>
            </div>
        </div>
    )
}
export default ColleDisabled