import './payment-status.styles.scss'

import { Fragment, useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { UserContext } from '../../3-context/0-user.context'
import { PaymentContext } from '../../3-context/2-payment.context'

import { getInfo, setInfo } from '../../4-utils/firebase.utils'
import { adressseCheckPaymentStatus } from '../../0-data/cloudAdress'

const PaymentStatus = () => {
    const {currentUser, setCurieux, setNombrePrompt} = useContext(UserContext)
    const {setIsSub} = useContext(PaymentContext)

    const location = useLocation()
    const session_id = new URLSearchParams(location.search).get('session_id')

    const [status, setStatus] = useState(false)

    useEffect(() => {
        const fetchPaymentStatus = async () => {
            try {
                if(session_id === 'curieux') 
                {
                    setStatus('curieux')
                    const curieuxStatus = await getInfo(currentUser.uid, 'curieux')
                    if(!curieuxStatus)
                    {
                        setInfo(currentUser.uid, 'curieux', true)
                        setCurieux(true)
                        setInfo(currentUser.uid, 'nombrePrompt', 1) 
                        setNombrePrompt(1)
                    }
                }
                else 
                {
                    const response = await fetch(adressseCheckPaymentStatus, {
                        method: "POST", 
                        headers: { 'Content-Type': 'application/json'},
                        body: JSON.stringify({ session_id: session_id}),
                    })
                    if (!response.ok) 
                    {
                        throw new Error("Erreur lors de la vérification de l'état du paiement")
                    }
                    setStatus('loading')
                    const data = await response.json() 
                    if (data.success) {
                        setStatus('success')
                        
                        const customerId = await getInfo(currentUser.uid, 'customerId')
                        if (!customerId) 
                        {
                            const email = await getInfo(currentUser.uid, 'email')
                            const requestBody = JSON.stringify({
                                email: email,
                            });
                            const res = await fetch("https://europe-west9-ai-kholler-409719.cloudfunctions.net/getCustomerId", {
                                method: "POST",
                                body: requestBody,
                                headers: {
                                'Content-Type': 'application/json'
                                }
                            })
                            const newCustomerId = await res.json()
                            await setInfo(currentUser.uid, 'customerId', newCustomerId)
                            
                        }
                        setInfo(currentUser.uid, 'abonne', true)
                        setInfo(currentUser.uid, 'nombrePrompt', 10) 
                        setNombrePrompt(10)                        
                        setInfo(currentUser.uid, 'date_abo' , Date.now())
                        setIsSub(true)
                    }
                    else setStatus('fail')
                }
            } catch(error) {
                console.log(error.message)
            }
        }
        if (session_id) fetchPaymentStatus()

    }, [session_id])

    const [name, setName] = useState('')
    useEffect(() => {
        const handleName = async () => {
            const nameTemp = await getInfo(currentUser.uid, 'prenom')
            setName(nameTemp)
        }
        currentUser && handleName()
    }, [currentUser])
   
    return (
        <div className='payment-state-container'>
            {status === 'success' ? (
                <Fragment>
                    <h1 className='title'>Bienvenue <span className='blue'>{name} !</span></h1>
                    <div className='btn-container'>
                        <Link to={'../analyse'}>
                            <button className='btn'>Passer ma première colle</button>
                        </Link>
                        <Link to={'../bibliotheque'}>
                            <button className='btn'>Découvrir la bibliothèque</button>
                        </Link>
                    </div>
                </Fragment>
            ): status === 'curieux' ? (
                <Fragment>
                    <h1 className='title'>Bienvenue <span className='blue'>{name} !</span></h1>
                    <div className='btn-container'>
                        <Link to={'../analyse'}>
                            <button className='btn'>Passer ma première colle</button>
                        </Link>
                    </div>
                    
                </Fragment>
            ) : status === 'fail' ?  (
                <h1 className='title'>Le paiement a échoué, merci de recommencer.</h1>
            ) : (
                <h1 className='title'>Le paiement est en cours de traitement...</h1>
            )}
        </div>
    )
}
export default PaymentStatus