import './audio-picker.styles.scss'
import './audio-picker.responsive.scss'

import { Fragment, useRef, useState, useContext } from 'react';

import { AudioContext } from '../../3-context/6-audio.context';

const mimeType = "audio/webm";

const AudioPicker = () => {
    const mediaRecorder = useRef(null);

    const { audioFile, setAudioFile, setAudioDuration} = useContext(AudioContext)

    const [actif, setActif] = useState(false)
    const [recording, setRecording] = useState(false);
    const [permission, setPermission] = useState(false);
    const [stream, setStream] = useState(null);
    const [audioChunks, setAudioChunks] = useState([]);

    const getMicrophonePermission = async () => {
        if ("MediaRecorder" in window) {
            try {
                const streamData = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                    video: false,
                });
                setPermission(true);
                setStream(streamData);
            } catch (err) {
                if(err.message === "Permission denied") 
                {
                    alert("Permission refusée")
                }
            }
        } else {
            alert("The MediaRecorder API is not supported in your browser.");
        }
    };

    const handleRecorderClick = async () => {
        await getMicrophonePermission()
        if (permission) setActif(true)
    }
    const startRecording = () => {
        if((audioFile && window.confirm("Êtes-vous certain de vouloir continuer ? L'ancien audio sera effacé.")) 
        ||!audioFile )
        {
            setRecording(true)
            startPauseTimer()

            const media = new MediaRecorder(stream, { type: mimeType });
            mediaRecorder.current = media;
            mediaRecorder.current.start();

            let localAudioChunks = [];
            mediaRecorder.current.ondataavailable = (event) => {
               if (typeof event.data === "undefined") return;
               if (event.data.size === 0) return;
               localAudioChunks.push(event.data);
            };
            setAudioChunks(localAudioChunks);
        }
    }
    const stopRecording = async () => {
        setRecording(false)
        setActif(false)
        startPauseTimer()        
        resetTimer()
        setAudioDuration(seconds);

        mediaRecorder.current.stop();
        mediaRecorder.current.onstop = () => {
           const audioBlob = new Blob(audioChunks, { type: mimeType });
           const newAudioFile = new File([audioBlob], 'votre_enregistrement_est_reussi.webm', {type: mimeType})
           setAudioFile(() => {return (newAudioFile)}) 
        };
    }
    const [seconds, setSeconds] = useState(0);
    const [isRunning, setIsRunning] = useState(false);

    const intervalRef = useRef();

    const startPauseTimer = () => {
      if (isRunning) {
        clearInterval(intervalRef.current);
      } else {
        intervalRef.current = setInterval(() => {
            setSeconds(prev => prev + 1)
        }, 1000);
      }
  
      setIsRunning(!isRunning);
    };
  
    const resetTimer = () => {
      clearInterval(intervalRef.current);
      setSeconds(() => 0);
      setIsRunning(false);
    };
    
    const handleChangeAudio = (e) => {
        const selectedFile = e.target.files[0];
        setAudioFile(selectedFile);

        if (selectedFile) {
            const reader = new FileReader();

            reader.onload = function (e) {
                const audioElement = new Audio();
                audioElement.src = e.target.result;

                audioElement.onloadedmetadata = function () {
                setAudioDuration(audioElement.duration);
                };
            };
            reader.readAsDataURL(selectedFile);
        }
    }
    return (
        <Fragment>
            <div className='audio-picker'>
                <div className={`${actif ? 'actif' : 'inactif'} ${recording ? 'recording' : ''} recorder`}>
                    {!actif ? (
                        <div className='recorder-btn' onClick={handleRecorderClick}>
                            <i className="fa-solid fa-microphone"></i>
                        </div>
                    ): (
                        <Fragment>
                            {recording && (
                                <Fragment>
                                    <div onClick={stopRecording} className='stop-recording'>Arrêter l'enregistrement</div>
                                    <i className="fa-regular fa-circle-dot record-icon"></i>
                                    <p className='timer'>{Math.floor(seconds / 60) }:
                                    {(seconds % 60) < 10 ? '0' + seconds % 60 : seconds % 60}s</p>
                                </Fragment>
                            )}
                            {permission && !recording &&(
                                <Fragment>
                                    <div onClick={startRecording} className='start-recording'>Lancer l'enregistrement</div>
                                    <div onClick={() => setActif(false)} className='close'>&#10005;</div>
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                </div>

                <label htmlFor="audio" className={`audio ${audioFile ? 'disabled' : actif ? 'hide' : ''}`}>
                    <div className='audio-input'>
                        {audioFile && audioFile.name === "votre_enregistrement_est_reussi.webm" ? 
                        'Votre enregistrement a bien été sauvegardé !' : audioFile ? audioFile.name 
                        :  'Sélectionner un fichier audio'}
                    </div>
                </label>
                {!audioFile && (
                    <input 
                        type="file" 
                        name="audio" 
                        id="audio" 
                        accept="audio/*"
                        onChange={handleChangeAudio}
                        style={{display: "none"}}
                    />
                )}
                {audioFile && (
                    <div className='suppr-audio' onClick={() => setAudioFile(null)}>&#10005;</div>
                )}
            </div>
            <span className='etat'>
                {audioFile ? '' : "Aucun fichier audio sélectionné"}
            </span>
        </Fragment>
    )
}
export default AudioPicker