export const listeSujetHGG = [
    { value: 'Histoire et notions économiques', label: "Histoire et notions économiques"},
    { value: 'Questions sociales', label: "Questions sociales"},
    { value: "L'État et l'économie", label: "L'État et l'économie"},
    { value: 'Mondialisation économique', label: "Mondialisation économique"},
    { value: 'Industrie et désindustrialisation', label: "Industrie et désindustrialisation"},
    { value: 'Pays émergents', label: "Pays émergents"},
    { value: 'Mondialisation commerciale', label: "Mondialisation commerciale"},
    { value: 'Mondialisation financière', label: "Mondialisation financière"},
    { value: 'Crises économiques et financières', label: "Crises économiques et financières"},
    { value: 'Transports', label: "Transports"},
    { value: 'Innovations technologiques', label: "Innovations technologiques"},
    { value: 'Culture', label: "Culture"},
    { value: 'Démographie', label: "Démographie"},
    { value: 'Villes', label: "Villes"},
    { value: 'Migrations', label: "Migrations"},
    { value: 'Tourisme', label: "Tourisme"},
    { value: 'Santé', label: "Santé"},
    { value: 'Eau', label: "Eau"},
    { value: 'Agriculture et alimentation', label: "Agriculture et alimentation"},
    { value: 'Énergies et matières premières', label: "Énergies et matières premières"},
    { value: 'Développement durable', label: "Développement durable"},
    { value: 'Espaces maritimes', label: "Espaces maritimes"},
    { value: 'Inégalités', label: "Inégalités"},
    { value: 'Géopolitique', label: "Géopolitique"},
]
export const listeSujetEu = [
    { value: 'Europe', label: "Europe"},
    { value: 'France', label: "France"},
    { value: 'URSS et Russie', label: "URSS et Russie"},
]
export const listeSujetAm = [
    { value: 'Etats-Unis', label: "Etats-Unis"},
    { value: 'Amérique latine', label: "Amérique latine"},
]
export const listeSujetAs = [
    { value: 'Asie', label: "Asie"},
    { value: 'Chine', label: "Chine"},
    { value: 'Japon', label: "Japon"},
    { value: 'Inde', label: "Inde"},
    
]
export const listeSujetESH = [
    { value: 'Les grandes fonctions économiques', label: "Les grandes fonctions économiques"},
    { value: 'Systèmes et organisations économiques', label: "Systèmes et organisations économiques"},
    { value: 'La révolution industrielle', label: "La révolution industrielle"},
    { value: "Les grands courants de l'analyse économique", label: "Les grands courants de l'analyse économique"},
    { value: "Croissance et développement", label: "Croissance et développement"},
    { value: "Stratégies de développement", label: "Stratégies de développement"},
    { value: "Entreprises, structures économiques et syndicats", label: "Entreprises, structures économiques et syndicats"},
    { value: "Monnaie et financement de l'économie", label: "Monnaie et financement de l'économie"},
    { value: "Rôle économique de l'État", label: "Rôle économique de l'État"},
    { value: "Sociologie", label: "Sociologie"},
    { value: "Démographie", label: "Démographie"},
    { value: "Économie approfondie", label: "Économie approfondie"},
    { value: "Internationalisation des économies", label: "Internationalisation des économies"},
    { value: "Économie monétaire et financière internationale", label: "Économie monétaire et financière internationale"},
    { value: "Europe économique, monétaire et sociale", label: "Europe économique, monétaire et sociale"},
    { value: "Inflation et déflation", label: "Inflation et déflation"},
    { value: "Marché du travail, emploi, chômage", label: "Marché du travail, emploi, chômage"},
    { value: "Crises et fluctuations", label: "Crises et fluctuations"},
    { value: "Politiques de régulation du cycle conjoncturel", label: "Politiques de régulation du cycle conjoncturel"},
    { value: "Politiques structurelles et interventions de l'Etat face aux défaillances de marché", label: "Politiques structurelles et interventions de l'Etat face aux défaillances de marché"},
    { value: "Justice sociale et politiques sociales", label: "Justice sociale et politiques sociales"},    
]